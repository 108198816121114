import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Usuario } from './core/models/usuario.model';
import { ApiService } from './core/service/api.service';
import { AuthService } from './core/service/auth.service';
import { Api } from './core/service/models/api.model';
import { Perfil } from './core/utils/perfil';
import { MenuDefinition } from './shared/components/menu/menu-definition';
import packageJson from '../../package.json';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    title = 'Talonário';
    user$: Observable<Usuario>;
    api: Api;
    usuario: Usuario;
    isAlert :boolean = false;
    versaoApi: string;

    menuItens: MenuDefinition[] = [];

    constructor(
        private authService: AuthService,
        private apiService: ApiService,
        private router: Router,
    ) {
        this.authService.login();
        this.authService.getUser().subscribe((usuario) => {
            this.usuario = usuario;
            this.buildMenu();
        });
        this.apiService.getApi().subscribe(api => this.versaoApi = api.buildVersion, error => this.versaoApi = 'Indisponível');
    }
    navigateHome(){
        
        this.router.navigate(['/inicio']);
       
    }

    handleLogout() {
        this.authService.logout();
    }

    buildMenu() {
        this.apiService.getApi().subscribe((api) => {
            this.api = api;

            this.createMenuHome();
            this.createMenuTalonarios();
            this.createMenuUnidade();
            this.createMenuPolicial();
            this.createMenuRelatorio();
            this.createMenuConfiguracoes();
        });
    }

    private createMenuTalonarios() {
        
        if (this.api._links?.talonarios) {
            let menuDef = {
                label: 'Gestão de Talonários',
                icon: 'insert_drive_file',
                subitens: [],
            };

            if (
                this.api.perfil
                    .map((r) => r.nome)
                    .includes(Perfil.GESTAO_TALONARIO)
            ) {
                menuDef.subitens.push({
                    label: 'Registrar solicitação (DETRAN)',
                    icon: 'search',
                    route: '/solicitar-talonario-detran',
                });
            }

            if (
                this.api.perfil
                    .map((r) => r.nome)
                    .includes(Perfil.GESTAO_TALONARIO)
            ) {
                menuDef.subitens.push({
                    label: 'Cadastrar Talonário',
                    icon: 'folder_open',
                    route: '/talonarios/cadastrar',
                });
            }

            if (
                this.api.perfil
                    .map((r) => r.nome)
                    .includes(Perfil.GESTAO_TALONARIO)
            ) {
                menuDef.subitens.push({
                    label: 'Distribuir Talonario Unidade',
                    icon: 'folder_open',
                    route: '/distribuir-talonario-unidade',
                });
            }

            if (
                this.api.perfil
                    .map((r) => r.nome)
                    .includes(Perfil.GESTAO_TALONARIO)
            ) {
                menuDef.subitens.push({
                    label: 'Remessa Detran',
                    icon: 'folder_open',
                    route: '/remessas/detran',
                });
                
                menuDef.subitens.push({
                    label: 'Remessa da Unidade',
                    icon: 'markunread_mailbox',
                    route: '/remessas/receber',
                });
            }
            
            if (
                this.api.perfil
                    .map((r) => r.nome)
                    .includes(Perfil.GESTAO_TALONARIO)
            ) {
                menuDef.subitens.push({
                    label: 'Cadastro de Auto de Infração',
                    icon: 'folder_open',
                    route: '/autoinfracao/cadastrar/gestao',
                });
            }

            if (menuDef.subitens.length > 0) {
                this.menuItens.push(menuDef);
            }
        }
    }

    private createMenuUnidade() {
        if (
            this.api._links?.remessas ||
            this.api._links?.solicitacaoTalonarios ||
            this.api._links?.distribuicaoTalonariosPoliciais
        ) {

            let menuDef = {
                label: 'Unidade',
                icon: 'card_travel',
                subitens: [],
            };

            if (
                this.api.perfil
                    .map((r) => r.nome)
                    .includes(Perfil.UNIDADE_TALONARIO)
            ) {
                menuDef.subitens.push({
                    label: 'Solicitações talonário (CPTRAN)',
                    icon: 'search',
                    route: '/solicitar-talonario-pm',
                });
            }

            if (
                this.api.perfil
                    .map((r) => r.nome)
                    .includes(Perfil.UNIDADE_TALONARIO)
            ) 
            // {
            //     menuDef.subitens.push({
            //         label: 'Histórico de talonário',
            //         icon: 'search',
            //         route: '/solicitar-talonario-pm/historico',
            //     });
            // }

            if (
                this.api.perfil
                    .map((r) => r.nome)
                    .includes(Perfil.UNIDADE_TALONARIO)
            ) {
                menuDef.subitens.push({
                    label: 'Remessa Auto de Infração',
                    icon: 'search',
                    route: '/remessas',
                });
            }

            if (
                this.api.perfil
                    .map((r) => r.nome)
                    .includes(Perfil.UNIDADE_TALONARIO)
            ) {
                menuDef.subitens.push({
                    label: 'Distribuir talonário PM',
                    icon: 'add',
                    route: '/distribuicoes-talonarios-policiais/distribuir',
                });
                menuDef.subitens.push({
                    label: 'Cadastro de Auto de Infração',
                    icon: 'folder_open',
                    route: '/autoinfracao/cadastrar/unidade',
                });
            }

            if (menuDef.subitens.length > 0) {
                this.menuItens.push(menuDef);
            }
        }
    }

    private createMenuPolicial() {

        if (this.api._links) {

            let menuDef = {
                label: 'Policial',
                icon: 'card_travel',
                subitens: [],
            };

            if (
                this.api.perfil
                    .map((r) => r.nome)
                    .includes(Perfil.POLICIAL_TALONARIO)
            ) {
                menuDef.subitens.push({
                    label: 'Receber talonário',
                    icon: 'search',
                    route: '/distribuicoes-talonarios-policiais/receber',
                });
            }

            if (
                this.api.perfil
                    .map((r) => r.nome)
                    .includes(Perfil.POLICIAL_TALONARIO)
            ) {
                menuDef.subitens.push({
                    label: 'Cadastro de Auto de Infração',
                    icon: 'folder_open',
                    route: '/autoinfracao/cadastrar',
                });
            }

            if (menuDef.subitens.length > 0) {
                this.menuItens.push(menuDef);
            }
        }
    }


    private createMenuHome() {

        if (
            //TODO definir regras de visualização.
            true
        ) {

            let menuDef = {
                label: 'Pagina Inicial',
                icon: 'home',
                route: '/inicio'

            };

            this.menuItens.push(menuDef);
        }
    }

    private createMenuRelatorio() {

            if (this.api._links) {

                let menuDef = {
                    label: 'Relatório',
                    icon: 'card_travel',
                    subitens: [],
                };
      

       

            /**if (
                this.api.recursos
                    .map((r) => r.nome)
                    .includes(Recursos.RECEBER_TALONARIO_POLICIAIS)
            ) 
            {
                menuDef.subitens.push({
                    label: 'Quantidade de Autos de Infração',
                    icon: 'folder_open',
                    route: '/quantidade-autos-infracao/relatorio',
                });
            }
            */

            if (
                this.api.perfil
                    .map((r) => r.nome)
                    .includes(Perfil.GESTAO_TALONARIO || Perfil.UNIDADE_TALONARIO)
            ) 
            {
                menuDef.subitens.push({
                    label: 'Ranking de infrações',
                    icon: 'folder_open',
                    route: '/ranking-infracoes/relatorio',
                });
            }
            if (
                this.api.perfil
                    .map((r) => r.nome)
                    .includes(Perfil.GESTAO_TALONARIO || Perfil.UNIDADE_TALONARIO)
            ) 
            {
                menuDef.subitens.push({
                    label: 'Histórico de Distribuição de talonário',
                    icon: 'folder_open',
                    route: '/historico-distribuicao-talonario/relatorio',
                });
            }

            if (
                this.api.perfil
                    .map((r) => r.nome)
                    .includes(Perfil.GESTAO_TALONARIO || Perfil.UNIDADE_TALONARIO)
            ) 
            {
                menuDef.subitens.push({
                    label: 'Remessas',
                    icon: 'folder_open',
                    route: '/consultar-remessa/relatorio',
                });
            }



           
            if (
                this.api.perfil
                    .map((r) => r.nome)
                    .includes(Perfil.POLICIAL_TALONARIO)
            )             
            // if (
                /* this.api.recursos
                    .map((r) => r.nome)
                    .includes(Recursos.RECEBER_TALONARIO_POLICIAIS) */
                // true
            // ) 
            {
     
            
            
                menuDef.subitens.push({
                    label: 'Histórico de AI',
                    icon: 'folder_open',
                    route: '/consultar-auto-infracao/relatorio',
                });
            
            
                menuDef.subitens.push({
                    label: 'Quantidade Auto Infração',
                    icon: 'folder_open',
                    route: '/consultar-quantidade-infracao/relatorio',
                });
            }
           

            if (menuDef.subitens.length > 0) {
                this.menuItens.push(menuDef);
            }
        }
    }

    private createMenuConfiguracoes() {

        if (this.api._links?.talonarios) {
            let menuDef = {
                label: 'Configuração',
                icon: 'settings_applications',
                subitens: [],
            };
            if (
                this.api.perfil
                    .map((r) => r.nome)
                    .includes(Perfil.GESTAO_TALONARIO)
            ) {
                menuDef.subitens.push({
                    label: 'Tipo Infração',
                    icon: 'search',
                    route: 'configuracao/tipo-infracao',
                });
                // menuDef.subitens.push({
                //     label: 'Teste / Auto Constatação',
                //     icon: 'search',
                //     route: 'configuracao/teste-alcoolemia',
                // });
            }    
            if (menuDef.subitens.length > 0) {
                this.menuItens.push(menuDef);
            }        
        }

    }

    get sobreInfo(): string {
        return `<p>${packageJson.description}</p>`;
    }

    get contatoInfo(): String {
        return `Qualquer dúvida, entrar em contato com:<br /><br />
            <b>DITEL/SAU:</b> 85800 ou 31905800<br />`;
    }

    get versaoFront(): String {
        return packageJson.version;
    }
}
