import { ModuleWithProviders, NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { NotificacaoService } from './service/notificacao.service';
import { ApiService } from './service/api.service';
import { AuthService } from './service/auth.service';
import { RecursoGuard } from './service/recurso-guard.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PerfilGuard } from './service/perfil-guard.service';


@NgModule({
    imports: [ToastrModule, FormsModule, ReactiveFormsModule]
})
export class AppCoreModule {
    static forRoot(): ModuleWithProviders<AppCoreModule> {
        return {
            ngModule: AppCoreModule,
            providers: [
                NotificacaoService,
                ApiService,
                AuthService,
                RecursoGuard,
                PerfilGuard,
                ...ToastrModule.forRoot().providers,
            ]
        };
    }
}
