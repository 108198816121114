import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Permite que o usuário acesse a rota caso tenha pelo menos uns dos recursos disponíveis na página
 */
@Injectable({
    providedIn: 'root',
})
export class PerfilGuard implements CanActivate {
    constructor(private api: ApiService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        let perfilParaAtivar: string[] = route.data.perfil;
        return this.api
            .getApi()
            .pipe(
                map((api) =>
                    api.perfil
                        .map((r) => r.nome)
                        .some((perfilGarantido) =>
                        perfilParaAtivar?.includes(perfilGarantido)
                        )
                )
            );
    }
}
