<ng-container *ngIf="usuario">
    <app-layout appName="SGPol - Talonário"
            appDescription="Sistema de Gerenciamento de Talonários"
            [menuItens]="menuItens"
            darkThemeClass="dark-theme"
            [usuario]="usuario.name"
            (logout)="handleLogout()"
            [contatoInfo]="contatoInfo"
            [sobreInfo]="sobreInfo"
            [versaoFront]="versaoFront"
            [versaoApi]="versaoApi">
        <router-outlet></router-outlet>
    </app-layout>
</ng-container>
