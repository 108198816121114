import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from './safe-html.pipe';
import { NumeroPorExtensoPipe } from './numero-por-extenso.pipe';
import { DecimalPipe } from '@angular/common';

@NgModule({
    declarations: [ SafeHtmlPipe, NumeroPorExtensoPipe ],
    imports: [],
    exports: [ SafeHtmlPipe, NumeroPorExtensoPipe ],
    providers: [DecimalPipe]
})
export class PipesModule {
}